import React from 'react';
import styled from '@emotion/styled';
import arrowsImg from '../images/arrows.png';
import pigImg from '../images/pig.png';
import calendarImg from '../images/calendar.png';
import cancelImg from '../images/cancel.png';
import likeImg from '../images/like.png';
import wishBackgroundImg from '../images/wish_background.png';
import { paths } from 'routes';
import { Link } from 'gatsby';
import { SCREEN_BREAKPOINTS } from 'constants/theme';

const Section = styled.section`
  min-height: calc(100vh - 300px);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 140px;
  padding-bottom: 220px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.lg}px) {
    padding-bottom: 120px;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.md}px) {
    padding-top: 100px;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    padding-bottom: 80px;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  text-align: center;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  max-width: 680px;
  margin-bottom: 50px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    font-size: 15px;
    margin-bottom: 40px;
  }
`;

const ArrowsImg = styled.img`
  height: 100px;
  margin-bottom: 50px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    height: 60px;
    margin-bottom: 30px;
  }
`;

const StepImg = styled.img`
  height: 65px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    height: 50px;
  }
`;

const StepHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    margin-bottom: 12px;
  }
`;

const StepTitle = styled.h4`
  font-size: 20px;
  margin-left: 18px;
  margin-bottom: 0;
  text-transform: uppercase;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    font-size: 16px;
  }
`;

const StepText = styled.p`
  font-size: 16px;
  white-space: pre-wrap;
  margin-bottom: 45px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    font-size: 14px;
  }
`;

const GoodbyeBlock = styled.div`
  background-image: url(${wishBackgroundImg});
  background-repeat: no-repeat;
  background-size: contain;
  height: 210px;
  width: 620px;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  margin-top: 100px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.md}px) {
    width: 480px;
    height: 160px;
    font-size: 20px;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    margin-top: 10px;
    width: 300px;
    height: 100px;
    font-size: 14px;
  }
`;

const Main = () => (
  <Section>
    <Title>Что делать после пробного занятия?</Title>
    <Subtitle>
      Мы рады,что вам и вашему ребенку понравилось пробное занятие! Для
      продолжения обучения, необходимо оплатить занятия и согласовать
      расписание. Ниже рассказываем о том, как это сделать.
    </Subtitle>
    <ArrowsImg src={arrowsImg} alt="Стрелки" />
    <ul className="list-unstyled">
      <li>
        <StepHeader>
          <StepImg src={pigImg} alt="Копилка" />
          <StepTitle className="mt-3">Пополните баланс занятий</StepTitle>
        </StepHeader>
        <StepText>
          Оплатить занятия можно на нашем сайте перейдя по{' '}
          <Link to={paths.payments} className="marked-link">
            ссылке
          </Link>
          .<br />
          Также вы можете оплатить по PayPal{' '}
          <a
            href="https://paypal.me/itgenik"
            className="marked-link"
            rel="noreferrer"
          >
            paypal.me/itgenik
          </a>
          <br />
          В зависимости от рекомендованного преподавателем формата обучения,
          индивидуально-группового или индивидуального, выберите оптимальный для
          вас пакет занятий.
          <br />
          После оплаты, пожалуйста, пришлите скриншот электронного чека
          администратору в мессенджер.
          <br />
          Обращаю ваше внимание, что при оплате пакета в течение <b>
            24 часов
          </b>{' '}
          после пробного урока вы получаете бонус: одно занятие для семьи в
          подарок!
          <br />
          Бонус действует только при первой оплате.
          <br />
          Оплаченный пакет занятий не прикрепляется к срокам.
        </StepText>
      </li>
      <li>
        <StepHeader>
          <StepImg src={calendarImg} alt="Календарь" />
          <StepTitle>Расписание</StepTitle>
        </StepHeader>
        <StepText>
          Вы можете забронировать определённые дни и время для занятий на{' '}
          <b>постоянной основе</b> (например, каждый вторник и пятницу в 19:00).
          <br />
          Или записываться на разовые занятия в удобные вам дни и время
          (например, на этой неделе среда 14:00, а на следующей четверг 17:00).
          <br />
          Это <b>свободное расписание</b>, при котором за учеником не
          закрепляется определенный преподаватель и время урока.
          <br />
          <br />
          Чтобы администратор подобрал для вас комфортное расписание,
          напишите,сколько раз в неделю вы хотите заниматься,
          <br />
          какие дни и время удобны и какой вид расписания, постоянный или
          свободный, вам подходит.
        </StepText>
      </li>
      <li>
        <StepHeader>
          <StepImg src={cancelImg} alt="Крест" />
          <StepTitle>Отмена и отработка занятия</StepTitle>
        </StepHeader>
        <StepText>
          Если вы предупреждаете об отмене занятия минимум за 8 часов до его
          начала, то мы его просто уберем из вашего расписания.
          <br />
          Если позже, то пропуск будет оплачиваемый и вам необходимо согласовать
          с администратором время для отработки.
          <br />
          Она бесплатная и доступна в течение 6 дней. Вы можете назначить
          отработку пропуска в то время, которое не пересекается <br />с вашим
          текущим расписанием. Если занятие не отработать, то оно “сгорает”.
        </StepText>
      </li>
      <li>
        <StepHeader>
          <StepImg src={likeImg} alt="Палец вверх и вниз" />
          <StepTitle>Если что-то не понравилось</StepTitle>
        </StepHeader>
        <StepText>
          Мы очень трепетно относимся к ситуациям, если ученику не понравилось
          занятие. Если такая ситуация произошла с вашим
          <br />
          ребенком, пожалуйста, не копите в себе, а сразу пишите нам! Мы
          тщательно разберем урок и дадим обратную связь. <br />
          Также мы будем признательны, если вы поделитесь положительным отзывом
          у нас на сайте!
        </StepText>
      </li>
    </ul>
    <GoodbyeBlock>Желаем продуктивного обучения!</GoodbyeBlock>
  </Section>
);

export default Main;
