import React from 'react';
import { SEO } from 'components/SEO';
import Main from './Main';

const Instruction = () => (
  <main className="page instruction-page container" title="Инструкция">
    <SEO title="Инструкция" />
    <Main />
  </main>
);

export default Instruction;
